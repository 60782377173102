import { Navigate, Route, Routes } from "react-router-dom";
import { Login } from "./Login";
import { LoginToken } from "./LoginToken";
import { CheckEmail } from "./CheckEmail";
import { useHeader } from "@Light/components/page/header/context";
import { useMemo } from "react";
import { Overlay } from "@Light/components/page/Overlay";
import { WithHeader } from "@Light/components/page/header";

export type LoginRoutesProps = {};

export const LoginRoutes: React.FC<LoginRoutesProps> = ({}) => {
  return (
    <Overlay>
      <WithHeader>
        <LoginRoutesWithHeader />
      </WithHeader>
    </Overlay>
  );
};

export type LoginRoutesWithHeaderProps = {};

export const LoginRoutesWithHeader: React.FC<
  LoginRoutesWithHeaderProps
> = ({}) => {
  const { setRightButton } = useHeader();
  setRightButton(useMemo(() => <div />, []));
  return (
    <Routes>
      <Route path="" element={<Login />} />
      <Route path="/check-email" element={<CheckEmail />} />
      <Route path="/token" element={<LoginToken />} />
      <Route path="/*" element={<Navigate to="/login" />} />
    </Routes>
  );
};
