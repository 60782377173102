import { Navigate, Route, Routes } from "react-router-dom";
import {
  WithConfiguration,
  hasFeatureFlag,
  useConfiguration,
} from "./WithConfiguration";
import { LoggedInApp } from "@Light/components/App";
import { LoginRoutes } from "./login/LoginRoutes";
import { WithAccount } from "@Light/components/page/account";
import { useGetAccountQuery, useLight } from "@Light/services/light";
import { Overlay } from "@Light/components/page/Overlay";
import { ShopRoutes } from "./shop/routes";
import { SignupRoutes } from "./signup/routes";
import { useAnalytics, useAnalyticsSearchParams } from "@Light/app/analytics";

export const AppRoutes: React.FC = () => {
  useAnalyticsSearchParams();
  return (
    <WithConfiguration>
      <ConfigurationRoutes />
    </WithConfiguration>
  );
};

export const ConfigurationRoutes: React.FC = () => {
  const configuration = useConfiguration();
  const { useGetAccountQuery } = useLight();
  const account = useGetAccountQuery();

  const hasPlanShopFeatureFlag = hasFeatureFlag(
    "CUSTOMER_CLIENT_SHOW_PLAN_SHOP",
  );
  const accountIsFinalized =
    account.isSuccess && account.data.enrollment.is_enrollment_finalized;
  const hasPlanShop = hasPlanShopFeatureFlag && !accountIsFinalized;

  if (!configuration || (!account.isSuccess && !account.isError)) {
    // TODO: Loading? Handling for nonexistant configuration?
    return null;
  }

  return (
    <Routes>
      {hasPlanShop && <Route path="/shop/*" element={<ShopRoutes />} />}
      <Route path="/*" element={<ConfigurationRoutesB1 account={account} />} />
    </Routes>
  );
};

export type ConfigurationRoutesB1Props = {
  account: ReturnType<typeof useGetAccountQuery>;
};

export const ConfigurationRoutesB1: React.FC<ConfigurationRoutesB1Props> = ({
  account,
}) => {
  const allowSignups = hasFeatureFlag("CUSTOMER_CLIENT_ALLOW_SIGNUPS");
  const { planUUID } = useAnalytics();
  if (account.isError) {
    return (
      <Routes>
        <Route path="/login/*" element={<LoginRoutes />} />
        {allowSignups && <Route path="/signup/*" element={<SignupRoutes />} />}
        <Route path="/*" element={<Navigate to="/login" />} />
      </Routes>
    );
  }

  return (
    <Overlay>
      <WithAccount>
        <Routes>
          <Route
            path="/test-error"
            element={
              <div
                onClick={() => {
                  throw new Error("Test Error");
                }}
              >
                Test Error
              </div>
            }
          />
          <Route path="/*" element={<LoggedInApp planUUID={planUUID} />} />
        </Routes>
      </WithAccount>
    </Overlay>
  );
};
