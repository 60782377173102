import { clsx } from "clsx";
import { Navigate, useSearchParams } from "react-router-dom";
import { useCallback } from "react";
import { useAPI } from "@/services/api";
import { useScaffold } from "@Light/scaffold";
import { PageBody } from "@Light/components/page/PageBody";
import { useHeader } from "@Light/components/page/header/context";

export type CheckEmailProps = {};

export const CheckEmail: React.FC<CheckEmailProps> = ({}) => {
  const searchParams = useSearchParams()[0];
  const email = searchParams.get("email") ?? "";
  const { setBackNavigate } = useHeader();

  setBackNavigate("/login");

  const { useLoginEmailTokenMutation } = useAPI();
  const [loginEmailToken] = useLoginEmailTokenMutation();

  const scaffold = useScaffold();

  const resendEmail = useCallback(() => {
    loginEmailToken({ email });
  }, [loginEmailToken, email]);

  if (!email) {
    return <Navigate to="/login" />;
  }

  return (
    <PageBody
      title="Check your inbox"
      subtitle={
        <div className="flex flex-col gap-2">
          <div>
            <span className={clsx(scaffold.page.colors.text.secondary)}>
              Please follow the link sent to
            </span>{" "}
            <span
              className={clsx("font-medium", scaffold.page.colors.text.primary)}
            >
              {email}
            </span>{" "}
            <span>to continue.</span>
          </div>
          <div>
            <span>You can also click</span>{" "}
            <span
              onClick={resendEmail}
              className={clsx(
                "font-medium cursor-pointer",
                scaffold.page.colors.text.primary,
              )}
            >
              here to resend
            </span>
            <span>.</span>
          </div>
        </div>
      }
    />
  );
};
